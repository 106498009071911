import styled from '@emotion/styled'
import { Grid, Group, AppShell, Title, Image } from '@mantine/core'
import React, { useState } from 'react'
import LanguageDropdown from './languageDropdown/languageDropdown'
import { useTranslation } from 'react-i18next'
import { useOrganizationView } from './organizationView/useOrganizationView'
import { UserMenu } from './UserMenu'

interface NavbarProps {
  drawerState: boolean
  toggleDrawer: React.Dispatch<React.SetStateAction<boolean>>
}

const TopNavbar = ({ drawerState, toggleDrawer }: NavbarProps) => {
  const { t } = useTranslation()

  const { orgData } = useOrganizationView()

  const ColFlexEnd = styled(Grid.Col)(() => ({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `flex-end`,
  }))

  const ColAlignCenter = styled(Grid.Col)(() => ({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
  }))

  return (
    <AppShell.Header p="xs">
      <Grid justify="space-between" align="center">
        <Group pl="md">
          {orgData?.logoUrl ? (
            <Image
              src={orgData?.logoUrl}
              alt={orgData?.displayName}
              fit="contain"
              w="auto"
              h={24}
            />
          ) : (
            <Title order={2}>{orgData?.displayName}</Title>
          )}
          <Title order={2}>-</Title>
          <Title order={2}>{t(`menu.administration`)}</Title>
        </Group>
        <ColAlignCenter span={`auto`}></ColAlignCenter>

        <ColFlexEnd span={`auto`}>
          <Group>
            <LanguageDropdown />
            <UserMenu />
          </Group>
        </ColFlexEnd>
      </Grid>
    </AppShell.Header>
  )
}

export default TopNavbar
