import { AppShell } from '@mantine/core'
import { type PageProps } from 'gatsby'
import React, { FC, useState } from 'react'

import SideMenu from './sideMenu'
import TopNavbar from './topnavbar'
import { Notifications } from '@mantine/notifications'
import { useAuth0 } from '@auth0/auth0-react'
import Loader from './loader'
import { Idle } from './Idle'

const Layout: FC<any> = ({ children }: PageProps) => {
  const [drawerState, setDrawerState] = useState(false)
  const { isLoading } = useAuth0()

  if (isLoading) {
    return <Loader />
  }

  return (
    <AppShell
      padding="md"
      navbar={{
        width: 140,
        breakpoint: 0,
        collapsed: { mobile: false },
      }}
      header={{ height: 52 }}
    >
      <TopNavbar drawerState={drawerState} toggleDrawer={setDrawerState} />
      <SideMenu />
      <AppShell.Main>
        {children}
        <Idle />
      </AppShell.Main>
      <Notifications position={`bottom-right`} />
    </AppShell>
  )
}

export default Layout
